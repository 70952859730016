import {
  Animated,
  Button,
  Card,
  CategoryHeading,
  Column,
  getAnimationVariant,
  Heading,
  Row,
  Text,
} from '@roar/components'
import { Link } from 'gatsby'
import React, { useCallback } from 'react'
import {
  Configure,
  connectHits,
  connectMenu,
  connectPagination,
  connectSearchBox,
  InstantSearch,
} from 'react-instantsearch-core'
import { Box, Flex } from 'rebass/styled-components'
import { searchClient } from '../common/algolia'
import { TEMPLATE_TYPES } from '../common/constants'
import useSeo from '../hooks/useSeo'
import { formatImage } from '../modules/ImageWrapper'
import Main from '../modules/Main'
import { SearchInput } from '../modules/SearchWrapper'
import HelmetWrapper from '../modules/HelmetWrapper'

const DATA_MAP = {
  title: ({ headline }) => headline.replace(/(<([^>]+)>)/gi, ''),
  description: ['description'],
}

const AlgoliaTemplate = (props) => {
  const {
    pageContext,
    location: { href },
  } = props
  const { headline, attribute, filters, description } = pageContext

  const seoProps = useSeo(pageContext, DATA_MAP, TEMPLATE_TYPES.POST_ARCHIVE)

  const stringHeadline = headline.replace(/(<([^>]+)>)/gi, '')

  return (
    <Main {...props} path={href}>
      <HelmetWrapper {...seoProps} />
      <Box bg="brandBeigeLight" pb={[4, 15]} px={[2, 14]}>
        <CategoryHeading headline={headline} description={description} headingAs="h1" />
        <InstantSearch searchClient={searchClient} indexName="roar_date_desc">
          <Configure filters={filters} hitsPerPage={9} />
          <ConnectedMenu attribute={attribute} placeholder={`Search ${stringHeadline}`} />
          <ConnectedHits />
          <ConnectedPagination />
        </InstantSearch>
      </Box>
    </Main>
  )
}

const CustomMenu = ({ refine, items, currentRefinement, placeholder }) => {
  const [isActive, setActive] = React.useState(false)
  const toggleActive = useCallback(
    (isOpen) => {
      setActive(isOpen)
      refine(null)
    },
    [setActive],
  )
  return (
    <Flex
      flexDirection={['column-reverse', 'column', 'row', 'row']}
      justifyContent="space-between"
      alignItems={['flex-start', 'flex-start', 'center', 'center']}
      mb={[2]}
    >
      <Flex width={[1, 1, 0.5, 0.5]} overflowX="auto">
        <Box flex="0 0 auto" mr={[2]}>
          <Button
            variant="text"
            hasUnderline={!currentRefinement}
            colorScheme={currentRefinement ? 'black' : 'red'}
            onClick={() => refine(null)}
          >
            <span>All</span>
          </Button>
        </Box>
        {items.map((item, index) => (
          <Box key={index} flex="0 0 auto" mx={[2]}>
            <Button
              variant="text"
              hasUnderline={item.isRefined}
              colorScheme={item.isRefined ? 'red' : 'black'}
              onClick={() => refine(item.value)}
            >
              <span>{item.label}</span>
            </Button>
          </Box>
        ))}
      </Flex>
      <Box mb={[2, 2, 0, 0]}>
        <ConnectedSearch placeholder={placeholder} onSetActive={toggleActive} active={isActive} />
      </Box>
    </Flex>
  )
}

const CustomHits = ({ hits }) => {
  return (
    <Row justifyContent="flex-start">
      {hits.map((hit, idx) => {
        const { objectID, date = '', name = '', excerpt = '', path } = hit

        return (
          <Column key={objectID} width={[1, 1, 6 / 12, 4 / 12]} mb={[2, 4]} flex="0 1 auto">
            <Link to={path}>
              <Animated shouldAnimate={true} variants={getAnimationVariant(idx)}>
                <Card
                  alignment="left"
                  variant={hit.logo ? 'press' : 'standard'}
                  image={formatImage(hit, true)}
                  logo={formatImage(hit, true, 'logo')}
                  meta={date ? () => <Heading size="h7">{date}</Heading> : null}
                  headline={name}
                  description={<Text>{excerpt}</Text>}
                />
              </Animated>
            </Link>
          </Column>
        )
      })}
    </Row>
  )
}

const CustomPagination = ({ currentRefinement, nbPages, refine }) => {
  const changePage = (pageNumber) => () => {
    refine(pageNumber)
    if (typeof window !== 'undefined') {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }

  return (
    <Flex justifyContent="space-between">
      {currentRefinement !== 1 ? (
        <Button onClick={changePage(currentRefinement - 1)}>
          <Flex alignItems="center">
            <i className="icon icon-down paginate-left" aria-hidden="true"></i>
            Previous
          </Flex>
        </Button>
      ) : (
        <div />
      )}
      {currentRefinement !== nbPages ? (
        <Button onClick={changePage(currentRefinement + 1)}>
          <Flex alignItems="center">
            Next
            <i className="icon icon-down paginate-right" aria-hidden="true"></i>
          </Flex>
        </Button>
      ) : (
        <div />
      )}
    </Flex>
  )
}

const ConnectedHits = connectHits(CustomHits)
const ConnectedMenu = connectMenu(CustomMenu)
const ConnectedPagination = connectPagination(CustomPagination)
const ConnectedSearch = connectSearchBox(SearchInput)

export default AlgoliaTemplate
